<template>
  <v-main>
    <v-container fluid class="fill-height">
      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'MinimalView',
  }
</script>
